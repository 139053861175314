import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useLocation, useNavigate } from 'react-router-dom';
import { sliderConfig, calculatePrice, buttonOptions } from './config/pricingConfig';
import './Tariffs.css';

function Tariffs() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const userId = params.get('userId');

  const [loading, setLoading] = useState(false);
  const [trafficAmount, setTrafficAmount] = useState(sliderConfig.min);
  const [price, setPrice] = useState(calculatePrice(sliderConfig.min));
  const [selectedButton, setSelectedButton] = useState(null);

  const handleTrafficChange = (e) => {
    const newTrafficAmount = parseInt(e.target.value, 10);
    setTrafficAmount(newTrafficAmount);
    setPrice(calculatePrice(newTrafficAmount));
    setSelectedButton(null);
  };

  const handleButtonClick = (amount, discount) => {
    setSelectedButton(amount);
    setPrice(calculatePrice(amount, discount));
    setTrafficAmount(sliderConfig.min);
  };

  const handlePayment = async (currentPrice, currentTrafficAmount) => {
    const invoiceId = uuidv4();

    setLoading(true);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/initialize-payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, amount: currentPrice, description: currentTrafficAmount * Math.pow(1024, 3), invoiceId }),
      });

      const data = await response.json();
      if (response.ok) {
        if (window.Telegram.WebApp) {
          window.Telegram.WebApp.openLink(data.paymentData.pay_url);
          navigate(`/payment-result?invoiceId=${invoiceId}`);
        } else {
          window.open(data.paymentData.pay_url, '_blank');
          navigate(`/payment-result?invoiceId=${invoiceId}`);
        }
      } else {
        console.error('Failed to initialize payment:', data.error);
        setLoading(false);
      }
    } catch (error) {
      console.error('Error:', error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handlePaymentClick = () => {
      handlePayment(price, selectedButton || trafficAmount); // Handle payment with button or slider value
    };

    tg.MainButton.setText("Получить трафик");
    tg.MainButton.show();
    tg.MainButton.onClick(handlePaymentClick);

    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/');
    });

    return () => {
      tg.MainButton.offClick(handlePaymentClick).hide();
      tg.BackButton.offClick().hide();
    };
  }, [price, trafficAmount, selectedButton]);

  const handleNavigateOffer = () => {
    navigate('/offer');
  };

  const handleNavigatePrivacy = () => { //
    navigate('/privacy');
  };

  return (
    <div className='wrapper'>
      <h1 className='text' style={{ fontSize: '22px', textAlign: 'center' }}>Выберите объем трафика</h1>
      
      <div style={{ marginTop: '20px', marginBottom: '20px', width: '100%', textAlign: 'center' }}>
        <span className='text-button-color' style={{ fontSize: '22px', fontWeight: 'bold' }}>
          {selectedButton || trafficAmount}
        </span><span> GB</span> за <span className='text-button-color' style={{ fontSize: '22px', fontWeight: 'bold' }}>{price}</span> <span>RUB</span>
        
        <div className={`slider-container ${selectedButton ? 'active-slider' : ''}`}>
          <input
            type="range"
            min={sliderConfig.min}
            max={sliderConfig.max}
            step={sliderConfig.step}
            value={trafficAmount}
            onChange={handleTrafficChange}
            style={{
              width: '100%',
              marginTop: '30px',
              marginBottom: '30px',
              borderRadius: '20px',
              background: selectedButton ? '#0088cc' : '',
            }}
            className='custom-range hint-color'
          />
        </div>
        <div className='text' style={{marginTop: '10px'}}>Ключ не ограничен по времени</div>
      </div>

      <div className="button-group column" style={{ textAlign: 'center', marginBottom: '30px' }}>
        {buttonOptions.map(({ amount, discount, description }) => (
          <div key={amount} className="button-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <button
              className={`traffic-button ${selectedButton === amount ? 'active' : ''}`}
              onClick={() => handleButtonClick(amount, discount)}
              style={{ marginRight: '15px' }}
            >
              {amount} GB
            </button>
            <span className="description-text">{description}</span>
          </div>
        ))}
      </div>

      <div className='description'>
        После оплаты лимит трафика обновится в личном кабинете автоматически. Если этого не произошло, перезагрузите мини-приложение.<br /><br />
        Чтобы использовать ключ, скачайте приложение Streisand или FoxRay<br /><br />
        Протокол соединения - VLESS<br /><br />
        {/*Нажимая на кнопку вы соглашаетесь с <span style={{color: '#0088cc', cursor: 'pointer'}} onClick={handleNavigatePrivacy}>Политикой конфиденциальности</span> и <span style={{color: '#0088cc', cursor: 'pointer'}} onClick={handleNavigateOffer}>Публичной офертой</span>*/}
      </div>
      
      {loading ? (
        <div style={{ marginTop: '20px' }} className='text'>
          <p>Секундочку...</p>
        </div>
      ) : null}
    </div>
  );
}

export default Tariffs;
