// pricingConfig.js
export const gbPrice = 15; // Define the price per GB

export const sliderConfig = {
  min: 20,
  max: 100,
  step: 1,
};

export const buttonOptions = [
    {
      amount: 100,
      discount: 0.10, // 10% discount
      description: 'Скидка 10%, дешевле на 150₽',
    },
    {
      amount: 200,
      discount: 0.20, // 20% discount
      description: 'Скидка 20%, дешевле на 600₽',
    },
    {
      amount: 500,
      discount: 0.40, // 40% discount
      description: 'Скидка 40%, дешевле на 3000₽',
    },
  ];

// Function to calculate price based on the amount
export const calculatePrice = (amount, discount = 0) => {
  const price = amount * gbPrice;
  return price - price * discount;
};

// Function to calculate amount from price if needed
export const calculateAmountFromPrice = (price) => {
  if (price / gbPrice > 500) {
    return price / (gbPrice * (1 - 0.40));
  } else if (price / gbPrice > 200) {
    return price / (gbPrice * (1 - 0.20));
  } else if (price / gbPrice > 100) {
    return price / (gbPrice * (1 - 0.10));
  } else {
    return price / gbPrice;
  }
};
