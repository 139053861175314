// /Home.js
import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfileSection from './components/ProfileSection';
import KeySection from './components/KeySection';
import ReferralSection from './components/ReferralSection';
import LoadingSpinner from './components/LoadingSpinner';
import PriceSlider from './components/PriceSlider';
import PriceButtonGroup from './components/PriceButtonGroup';
import { sliderConfig, calculatePrice, buttonOptions } from './config/pricingConfig';
import { TelegramThemeContext } from './contexts/TelegramThemeContext';
import './Home.css';

function Home({ verificationFailed }) {
  const [loading, setLoading] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const [trafficAmount, setTrafficAmount] = useState(sliderConfig.min);
  const [price, setPrice] = useState(calculatePrice(sliderConfig.min));
  const [selectedButton, setSelectedButton] = useState(null);
  const theme = useContext(TelegramThemeContext);
  const navigate = useNavigate();

  const rawMarzbanUserData = sessionStorage.getItem('marzbanUserData');
  const marzbanUserData = rawMarzbanUserData ? JSON.parse(rawMarzbanUserData) : null;

  const storedUserData = sessionStorage.getItem('userData');
  const userData = storedUserData ? JSON.parse(storedUserData) : null;

  const profilePictureUrl = '/logo.jpg';

  useEffect(() => {
    setLoading(false);
  }, []);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    const handleNavigateTariffs = () => {
      if (userData?.id) {
        navigate(`/tariffs?userId=${userData.id}`);
      } else {
        setResponseMessage('User ID is missing.');
      }
    };

    // Set up MainButton and show it
    tg.MainButton.setText("Получить еще трафик").show().onClick(handleNavigateTariffs);

    // Clean up the MainButton on component unmount
    return () => {
      tg.MainButton.offClick(handleNavigateTariffs).hide();
    };
  }, [userData, navigate]);

  const handleNavigateToBot = () => {
    window.Telegram.WebApp.openLink(`https://t.me/${process.env.REACT_APP_BOT_NAME}`); //
  };

  const handleNavigateToChannel = () => {
    window.Telegram.WebApp.openLink(`https://t.me/pvtkey`);
  };

  const handleNavigateToSupport = () => {
    window.Telegram.WebApp.openLink(`https://t.me/pvt_key`);
  };

  const handleNavigateKeyInfo = () => {
    navigate('/key-info');
  };

  const handleNavigateReferralInfo = () => {
    navigate('/referral-info');
  };

  const handleNavigateOffer = () => {
    navigate('/offer');
  };

  const handleNavigatePrivacy = () => { //
    navigate('/privacy');
  };

  if (verificationFailed) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div className="wrapper" style={{ width: '100%', maxWidth: '400px' }}>
          <h1>PVT key</h1>
          <div>Ключи от интернета</div>
          <PriceSlider
            trafficAmount={trafficAmount}
            handleTrafficChange={(e) => setTrafficAmount(e.target.value)}
            selectedButton={selectedButton}
            price={price}  // Pass price as a prop
          />
          <PriceButtonGroup
            buttonOptions={buttonOptions}
            selectedButton={selectedButton}
            handleButtonClick={(amount, discount) => {
              setSelectedButton(amount);
              setPrice(calculatePrice(amount, discount));
            }}
          />
          <button
            onClick={() => window.location.href = `https://t.me/${process.env.REACT_APP_BOT_NAME}`}
            style={{
              backgroundColor: '#0088cc',
              border: 'none',
              borderRadius: '10px',
              color: 'white',
              padding: '10px 20px',
            }}
          >
            Начать пользоваться
          </button>

          <button
            style={{
              marginTop: '20px',
              border: 'none',
              background: 'none',
              color: '#0088cc',
            }}
            onClick={handleNavigatePrivacy}
          >
            Политика конфиденциальности
          </button>

          <button
            style={{
              marginTop: '10px',
              border: 'none',
              background: 'none',
              color: '#0088cc',
            }}
            onClick={handleNavigateOffer}
          >
            Публичная оферта
          </button>
        </div>
      </div>
    );
  }
  return (
    <div>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="wrapper">
          <ProfileSection
            profilePictureUrl={profilePictureUrl} // Use logo.jpg for profile picture
            userData={userData}
            handleNavigateToBot={handleNavigateToBot}
            handleNavigateToChannel={handleNavigateToChannel}
            handleNavigateToSupport={handleNavigateToSupport}
          />
          {marzbanUserData && (
            <KeySection marzbanUserData={marzbanUserData} handleNavigateKeyInfo={handleNavigateKeyInfo} />
          )}
          <ReferralSection userData={userData} handleNavigateReferralInfo={handleNavigateReferralInfo} />
          
          {/* Display response message if there's an error */}
          {responseMessage && (
            <div style={{ marginTop: '20px', color: 'green' }}>
              <p>{responseMessage}</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Home;
