import { useEffect } from 'react';

export const useDeviceInfo = (userAgent) => {
  const iphoneLink = 'https://apps.apple.com/app/id6450534064';
  const androidLink = 'https://play.google.com/store/apps/details?id=com.v2ray.ang&pcampaignid=web_share';
  const macLink = 'https://apps.apple.com/ru/app/foxray/id6448898396';

  function getDeviceInfo(userAgent) {
    if (/iphone|ipad|ipod/i.test(userAgent)) {
      return { deviceType: "iOS", link: iphoneLink, images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] };
    } else if (/android/i.test(userAgent)) {
      return { deviceType: "Android", link: androidLink, images: ['/android.jpg'] };
    } else if (/macintosh|mac os x/i.test(userAgent)) {
      return { deviceType: "Mac", link: macLink, images: ['/manual_ios1.jpg', '/manual_ios2.jpg'] }; //ЗАМЕНИТЬ
    } else if (/windows/i.test(userAgent)) {
      return { deviceType: "Windows PC", link: null, images: ['/manual_windows1.jpg', '/manual_windows2.jpg', '/manual_windows3.jpg', '/manual_windows4.jpg'] };
    } else {
      return { deviceType: "Unknown device", link: null, images: [] };
    }
  }

  useEffect(() => {
    const deviceInfo = getDeviceInfo(userAgent);
    if (!sessionStorage.getItem('deviceInfo')) {
      sessionStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
    }
  }, [userAgent]);
};
