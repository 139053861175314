import React, { useContext, useState, useCallback } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { TelegramThemeProvider } from './contexts/TelegramThemeContext';
import { TelegramThemeContext } from './contexts/TelegramThemeContext';
import ThemedGlobalStyle from './GlobalStyles';
import AnimatedRoutes from './routes/AnimatedRoutes';
import { useTelegramVerification } from './hooks/useTelegramVerification';
import { useDeviceInfo } from './hooks/useDeviceInfo';

function App() {
  const theme = useContext(TelegramThemeContext);
  const tg = window.Telegram.WebApp;
  const userAgent = navigator.userAgent.toLowerCase();
  const [marzbanUser, setMarzbanUser] = useState(null);

  // Custom hook to store device info in sessionStorage
  useDeviceInfo(userAgent);

  // Memoized function to fetch Marzban user data
  const fetchMarzbanUser = useCallback(async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/get-marzban-user?userId=${userId}`);
      const data = await response.json();

      if (response.ok && data !== marzbanUser) {
        setMarzbanUser(data);
        sessionStorage.setItem('marzbanUserData', JSON.stringify(data));
      } else if (!response.ok) {
        await handleCreateMarzbanUser(userId);
      }
    } catch (error) {
      console.error('Error fetching Marzban user:', error);
    }
  }, [marzbanUser]);

  // Memoized function to create a new Marzban user
  const handleCreateMarzbanUser = useCallback(async (userId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/create-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId }),
      });
      const data = await response.json();

      if (response.ok) {
        setMarzbanUser(data);
        sessionStorage.setItem('marzbanUserData', JSON.stringify(data));
      } else {
        console.error('Failed to create user:', data.error);
      }
    } catch (error) {
      console.error('Error creating Marzban user:', error);
    }
  }, []);

  const { userData, loading, verificationFailed } = useTelegramVerification(tg, marzbanUser, fetchMarzbanUser);

  if (loading) {
    return (
      <div className='text' style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
        <p>Загрузка...</p>
      </div>
    );
  }

  return (
    <TelegramThemeProvider>
      <ThemedGlobalStyle />
      <Router>
        <AnimatedRoutes verificationFailed={verificationFailed} />
      </Router>
    </TelegramThemeProvider>
  );
}

export default App;
