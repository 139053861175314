import React from 'react';

const PriceButtonGroup = ({ buttonOptions, selectedButton, handleButtonClick }) => {
  return (
    <div className="button-group column" style={{ textAlign: 'center', marginBottom: '30px' }}>
      {buttonOptions.map(({ amount, discount, description }) => (
        <div key={amount} className="button-item" style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
          <button
            className={`traffic-button ${selectedButton === amount ? 'active' : ''}`}
            onClick={() => handleButtonClick(amount, discount)}
            style={{ marginRight: '15px' }}
          >
            {amount} GB
          </button>
          <span className="description-text">{description}</span>
        </div>
      ))}
    </div>
  );
};

export default PriceButtonGroup;
