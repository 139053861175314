import React from 'react';
import { sliderConfig } from '../config/pricingConfig';
import '../Tariffs.css'

const PriceSlider = ({ trafficAmount, handleTrafficChange, selectedButton, price }) => {
  return (
    <div style={{ marginTop: '20px', marginBottom: '20px', width: '100%', maxWidth: '600px', textAlign: 'center' }}>
      <span className='text-button-color' style={{ fontSize: '22px', fontWeight: 'bold' }}>
        {selectedButton || trafficAmount}
      </span><span> GB</span> за <span className='text-button-color' style={{ fontSize: '22px', fontWeight: 'bold' }}>{price}</span> <span>RUB</span>
      <div className={`slider-container ${selectedButton ? 'active-slider' : ''}`}>
        <input
            type="range"
            min={sliderConfig.min}
            max={sliderConfig.max}
            step={sliderConfig.step}
            value={trafficAmount}
            onChange={handleTrafficChange}
            style={{
              width: '100%',
              marginTop: '30px',
              marginBottom: '30px',
              borderRadius: '20px',
              background: selectedButton ? '#0088cc' : '',
          }}
          className='custom-range hint-color'
        />
      </div>
    </div>
  );
};

export default PriceSlider;
