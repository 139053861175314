import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { gbPrice, calculateAmountFromPrice } from './config/pricingConfig';
import Modal from './components/Modal';

function InfoReferral() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [maxTraffic, setMaxTraffic] = useState(null);
  const [newBalance, setNewBalance] = useState(null);

  const rawMarzbanUserData = sessionStorage.getItem('marzbanUserData');
  const marzbanUserData = JSON.parse(rawMarzbanUserData);

  const storedUserData = sessionStorage.getItem('userData');
  const userData = JSON.parse(storedUserData);

  useEffect(() => {
    const tg = window.Telegram.WebApp;

    tg.MainButton.offClick();

    const handleMainButtonClick = () => {
      if (userData.referral_balance >= gbPrice) {
        const traffic = calculateAmountFromPrice(userData.referral_balance);
        setMaxTraffic(traffic);

        const bytesTraffic = traffic * 1073741824;
        setNewBalance(bytesTraffic + marzbanUserData.data_limit);
        setShowModal(true);
      } else {
        setShowModal(true);
      }
    };

    tg.MainButton.setText("Использовать реферальный баланс");
    tg.MainButton.show();
    tg.MainButton.onClick(handleMainButtonClick);

    tg.BackButton.show();
    tg.BackButton.onClick(() => {
      navigate('/');
    });

    return () => {
      tg.MainButton.offClick(handleMainButtonClick).hide();
      tg.BackButton.offClick().hide();
    };
  }, [navigate, userData.referral_balance]);

  // Function to handle the redemption of referral cash
  const redeemReferralCash = async () => {
    setLoading(true);
    setShowModal(false);  // Hide the modal after confirming

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/redeem-referral`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId: userData.id, newBalance: newBalance, keyId: marzbanUserData.username }),
      });

      if (response.ok) {
        const result = await response.json();
        
        // Check if the result status is success
        if (result.message === 'Referral balance updated successfully') {
          window.location.reload();
        } else {
          alert('Произошла ошибка при использовании средств.');
        }
      } else {
        // Handle the case when response status is not OK (e.g., 404, 500)
        const errorResult = await response.json();
        alert(errorResult)
      }
    } catch (error) {
      // Catch any unexpected errors
      console.error('Error redeeming referral cash:', error);
    }

    setLoading(false);
  };

  return (
    <div className='wrapper'>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          <h1 className='text' style={{ fontSize: '22px', textAlign: 'left', marginBottom: '20px' }}>
            Реферальная программа
          </h1>
          <div className='text'>
            Ваш реферальный баланс: <span style={{ fontWeight: 'bold' }}>{userData.referral_balance} RUB</span>
          </div>
          <div className='text'>
            Вы можете использовать накопленные реферальные средства для покупки дополнительного трафика.
          </div>
        </>
      )}

      {/* Conditionally render the Modal */}
      {showModal && (
        <Modal
          title={userData.referral_balance >= gbPrice ? 'Подтверждение' : 'Недостаточно средств'}
          message={
            userData.referral_balance >= gbPrice
              ? `Получить ${maxTraffic} GB трафика за накопленные бонусы?`
              : `Вам нужно минимум ${gbPrice} RUB для использования реферального баланса.`
          }
          confirmText={userData.referral_balance >= gbPrice ? 'Получить' : 'OK'}
          cancelText={userData.referral_balance >= gbPrice ? 'Отмена' : ''}
          onConfirm={userData.referral_balance >= gbPrice ? redeemReferralCash : () => setShowModal(false)}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
}

export default InfoReferral;
